export const marketplaceAddress = "0x660C56a3f42D9527324af8A51CBe78E8f2Db17aE";
export const auctionAddress = "0xFA2238eEb4E2E5612853BF29f925a36f971F9010";

export const manifoldOpenEditionAddress =
  "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";

export const contractAddress = "0x8FB216908238BA7b00971dAf246d944E5cC44eb5";

export const artistId = 3085;
export const artistAddress = "0xDa48F08ebB71fa5778d02ad243cbF73fBB74C4C7";

export const projectName = "Landscape Parables";
export const projectLink = "https://willard.ninfa.io";

export const etherscanUrl = "https://etherscan.io";
export const basescanUrl = "https://basescan.org";

export const startingDate = 1723478400000;

export const artistName = "WILLARD";
export const bio = [
  "WILLARD is a contemporary artist exploring the poetic depth of ordinary things. ",

  "His work integrates generative coding, digital rendering, and post-processing techniques to create uncanny scenes featuring a variety of familiar structures, including barns, water towers, gas stations, houses, and swing sets. ",

  "Through this pictorial representation of landscape, Willard weaves together narrative threads and thematic commentary on the melancholy of everyday life.",
];

export const ninfaUrl = "https://ninfa.io";

export const artistUrl = "https://ninfa.io/@WILLARD";
export const artistTwitter = "https://x.com/ordinarywillard";
export const artistInsta = "";

export const interviewPath = "/interview/A-conversation-with-Willard-16";
export const articlePath = `/article/Unveiling-the-Uncanny:-Willard's-"Landscape-Parables"-and-Its-Artistic-Parallels-17`;
export const standard = "ERC-721";
export const mintedOn = "Transient Labs";
export const totalPieces = "10";

export const validChainId = 1;

export const openEdition = {
  title: "Placeholder",
  description: "",
  price: 0,
  instanceId: 0,
  contractAddress: "0x0B4B102742A68B422B02563CFe226a3ba2bA3420",
  tokenId: 1,
  originalImageUrl: "",
  metadata: "",
  startDate: 1715097600,
  endDate: 1715184000,
} as const;

export const manifoldFee = 0.0005;

export const compressedImageBaseUrl = "https://images.ninfa.io/nfts/original";
